import { SitePage } from 'app/layout';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ColourBlock } from '@vega/common-react';
import iconChild from '../images/icon-child.png';
// import { Link } from 'gatsby';
import { i18next } from '@vega/common';

export default function ContactPage() {
  const theme = useTheme();
  const t = i18next.t;
  return (
    <SitePage>
      <ColourBlock
        backgroundColor="seafoam.main"
        sx={{
          height: '100%',
          flexGrow: 1,
          pt: theme.spacing(7),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box mb={2}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mx: 3 }}>
            <Box sx={{ width: { md: '30rem' } }}>
              <Typography variant="h4" paragraph fontWeight="bold" color="white">
                {t('contact-us-page.title')}
              </Typography>
              <Typography paragraph>{t('contact-us-page.description')}</Typography>
              <Typography paragraph color="white">
                <strong>{t('general.email')}:</strong> gmci.c@monash.edu
                <br />
                {/* <strong>Phone:</strong> (02)&nbsp;9114&nbsp;4326 */}
              </Typography>
              {/* <Typography paragraph>
                You might also find some of these answers on the{' '}
                <Link to="/faqs">Frequently Asked Questions (FAQs)</Link> page.
              </Typography> */}
            </Box>
            <img src={iconChild} alt="" />
          </Box>
        </Box>
      </ColourBlock>
    </SitePage>
  );
}
